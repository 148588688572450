export enum Locales {
  EN = 'en',
  RU = 'ru',
  UA = 'uk',
  ES = 'es',
  TR = 'tr',
}

export const LOCALES = [
  { value: Locales.EN, caption: 'English' },
  { value: Locales.RU, caption: 'Русский' },
  { value: Locales.ES, caption: 'Español' },
  { value: Locales.TR, caption: 'Turkish' },
  { value: Locales.UA, caption: 'Українська' },
];
