import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret,
  faRotateLeft,
  faRotateRight,
  faTimes,
  faHouse,
  faMagnifyingGlass,
  faUser,
  faDownload,
  faImage,
  faPhone,
  faBars,
  faEnvelope,
  faStar,
  faLocationDot,
  faMusic,
  faWandMagicSparkles,
  faHeart,
  faCircleXmark,
  faCameraRetro,
  faCloud,
  faComment,
  faTruckFast,
  faPenNib,
  faCalendarDays,
  faPaperclip,
  faShieldHalved,
  faFile,
  faBell,
  faCartShopping,
  faClipboard,
  faFilter,
  faCircleInfo,
  faCircleUser,
  faPen,
  faGift,
  faFilm,
  faList,
  faGear,
  faTrash,
  faCircleUp,
  faCircleDown,
  faInbox,
  faLock,
  faBook,
  faBookmark,
  faCamera,
  faVideo,
  faCircleExclamation,
  faFire,
  faKey,
  faGears,
  faPaperPlane,
  faGlobe,
  faTruck,
  faTicket,
  faAddressBook,
  faHandshake,
  faUsers,
  faShop,
  faChartSimple,
  faShirt,
  faHandHoldingHeart,
  faCalendar,
  faCreditCard,
  faNotesMedical,
  faTable,
  faStore,
  faFlag,
  faNetworkWired,
  faRocket,
  faWallet,
  faAward,
  faSeedling,
  faTrophy,
  faCube,
  faCircleCheck,
  faRoute,
  faRecycle,
  faCoins,
  faPuzzlePiece,
  faDice,
  faHeadset,
  faUsersLine,
  faTriangleExclamation,
  faSuitcase,
  faShareNodes,
  faScrewdriverWrench,
  faScaleBalanced,
  faSackDollar,
  faPiggyBank,
  faPaintbrush,
  faMobileScreenButton,
  faMasksTheater,
  faMapLocationDot,
  faIdCard,
  faHourglassHalf,
  faHandHoldingDollar,
  faGraduationCap,
  faCubes,
  faChartPie,
  faBookOpen,
  faBasketShopping,
  faLayerGroup,
  faEdit,
  faRobot,
  faBriefcase,
  faCopy,
  faLink,
  faBullhorn,
  faComments,
  faTableList,
  faBarsProgress,
  faSquareCheck,
  faSignHanging,
  faIndent,
  faNewspaper,
  faFileLines,
  faCodeCompare,
  faSitemap,
  faCode,
  faXmark,
  faUpDownLeftRight,
  faAlignLeft,
  faArrowsUpDown,
  faChevronRight,
  faPlus,
  faTag,
  faBullseye,
  faUserGroup,
  faBan,
  faFolderClosed,
  faImages,
  faPanorama,
  faDeleteLeft,
  faKeyboard,
  faPhotoFilm,
  faStopwatch,
  faCommentDots,
  faCheck,
  faClock,
  faTrashCan,
  faHeading,
  faHandPointer,
  faHistory,
  faDollar,
  faMoneyBillTrendUp,
  faArrowsRotate,
  faCircleArrowUp,
  faUserMinus,
  faUserPlus,
  faPlay,
  faAt,
  faCheckCircle,
  faCheckSquare,
  faArrowsAltH,
  faGripVertical,
  faTh,
  faCalendarAlt,
  faWindowMinimize,
  faUsersRectangle,
  faUsersRays,
  faTicketSimple,
  faQuestionCircle,
  faMap,
  faCartPlus,
  faShoppingCart,
  faMinus,
  faChevronLeft,
  faReceipt,
  faSliders,
  faPalette,
  faMagnet,
  faUmbrella,
  faDroplet,
  faPaw,
  faMugHot,
  faBurger,
  faPizzaSlice,
  faHotdog,
  faBowlFood,
  faIceCream,
  faGlassWaterDroplet,
  faCakeCandles,
  faWineGlass,
  faMartiniGlass,
  faFishFins,
  faCubesStacked,
  faAppleWhole,
  faPersonSwimming,
  faHotTubPerson,
  faTreeCity,
  faSpa,
  faShippingFast,
  faBowlingBall,
  faVolleyball,
  faBasketball,
  faFutbol,
  faPersonHiking,
  faPersonBiking,
  faPersonRunning,
  faShoePrints,
  faGaugeHigh,
  faChampagneGlasses,
  faTableTennisPaddleBall,
  faGamepad,
  faCarSide,
  faBrain,
  faLeaf,
  faMotorcycle,
  faMask,
  faUserTie,
  faBaby,
  faUmbrellaBeach,
  faDumbbell,
  faYinYang,
  faUtensils,
  faTv,
  faTents,
  faSpoon,
  faSailboat,
  faScissors,
  faPlaneUp,
  faHorse,
  faGuitar,
  faDesktop,
  faLaptop,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faTrashAlt,
  faUserSecret,
  faRotateLeft,
  faRotateRight,
  faTimes,
  faHouse,
  faMagnifyingGlass,
  faUser,
  faDownload,
  faImage,
  faPhone,
  faBars,
  faBarsProgress,
  faEnvelope,
  faStar,
  faLocationDot,
  faMusic,
  faWandMagicSparkles,
  faHeart,
  faCircleXmark,
  faCameraRetro,
  faCloud,
  faComment,
  faTruckFast,
  faPenNib,
  faCalendarDays,
  faPaperclip,
  faShieldHalved,
  faFile,
  faBell,
  faCartShopping,
  faClipboard,
  faFilter,
  faCircleInfo,
  faCircleUser,
  faPen,
  faGift,
  faFilm,
  faList,
  faGear,
  faTrash,
  faCircleUp,
  faCircleDown,
  faInbox,
  faLock,
  faBook,
  faBookmark,
  faCamera,
  faVideo,
  faCircleExclamation,
  faFire,
  faKey,
  faGears,
  faPaperPlane,
  faGlobe,
  faTruck,
  faTicket,
  faAddressBook,
  faHandshake,
  faUsers,
  faShop,
  faChartSimple,
  faShirt,
  faHandHoldingHeart,
  faCalendar,
  faCreditCard,
  faNotesMedical,
  faTable,
  faStore,
  faFlag,
  faNetworkWired,
  faRocket,
  faWallet,
  faAward,
  faSeedling,
  faTrophy,
  faCube,
  faCircleCheck,
  faRoute,
  faRecycle,
  faCoins,
  faPuzzlePiece,
  faDice,
  faHeadset,
  faUsersLine,
  faTriangleExclamation,
  faSuitcase,
  faShareNodes,
  faScrewdriverWrench,
  faScaleBalanced,
  faSackDollar,
  faPiggyBank,
  faPaintbrush,
  faMobileScreenButton,
  faMasksTheater,
  faMapLocationDot,
  faIdCard,
  faHourglassHalf,
  faHandHoldingDollar,
  faGraduationCap,
  faCubes,
  faChartPie,
  faBookOpen,
  faBasketShopping,
  faLayerGroup,
  faEdit,
  faRobot,
  faBriefcase,
  faCopy,
  faLink,
  faBullhorn,
  faComments,
  faTableList,
  faSquareCheck,
  faSignHanging,
  faIndent,
  faNewspaper,
  faFileLines,
  faCodeCompare,
  faSitemap,
  faCode,
  faXmark,
  faUpDownLeftRight,
  faAlignLeft,
  faArrowsUpDown,
  faChevronRight,
  faPlus,
  faTag,
  faBullseye,
  faUserGroup,
  faBan,
  faFolderClosed,
  faImages,
  faPanorama,
  faDeleteLeft,
  faKeyboard,
  faPhotoFilm,
  faStopwatch,
  faCommentDots,
  faCheck,
  faClock,
  faTrashCan,
  faHeading,
  faHistory,
  faHandPointer,
  faDollar,
  faMoneyBillTrendUp,
  faArrowsRotate,
  faCircleArrowUp,
  faUserMinus,
  faUserPlus,
  faPlay,
  faAt,
  faCheckCircle,
  faCheckSquare,
  faArrowsAltH,
  faGripVertical,
  faTh,
  faCalendarAlt,
  faWindowMinimize,
  faUsersRectangle,
  faUsersRays,
  faTicketSimple,
  faQuestionCircle,
  faMap,
  faCartPlus,
  faShoppingCart,
  faMinus,
  faChevronLeft,
  faReceipt,
  faSliders,
  faPalette,
  faMagnet,
  faUmbrella,
  faDroplet,
  faPaw,
  faMugHot,
  faBurger,
  faPizzaSlice,
  faHotdog,
  faBowlFood,
  faIceCream,
  faGlassWaterDroplet,
  faCakeCandles,
  faWineGlass,
  faMartiniGlass,
  faFishFins,
  faCubesStacked,
  faAppleWhole,
  faPersonSwimming,
  faHotTubPerson,
  faTreeCity,
  faSpa,
  faShippingFast,
  faBowlingBall,
  faVolleyball,
  faBasketball,
  faFutbol,
  faPersonHiking,
  faPersonBiking,
  faPersonRunning,
  faShoePrints,
  faGaugeHigh,
  faChampagneGlasses,
  faTableTennisPaddleBall,
  faGamepad,
  faCarSide,
  faBrain,
  faLeaf,
  faMotorcycle,
  faMask,
  faUserTie,
  faBaby,
  faUmbrellaBeach,
  faDumbbell,
  faYinYang,
  faUtensils,
  faTv,
  faTents,
  faSpoon,
  faSailboat,
  faScissors,
  faPlaneUp,
  faHorse,
  faGuitar,
  faDesktop,
  faLaptop,
);

export default library;
