import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MlmState } from './state';

const defaultState: MlmState = {
  packagesList: [],
  activePackage: null,
  qualification: null,
  invoiceResponse: null,
  binaryTree: {},
  recentRegistrations: {
    next: 0,
    previous: 0,
    current: 0,
    count: 0,
    results: [],
  },
  transactionsResponse: {
    next: 0,
    previous: 0,
    current: 0,
    count: 0,
    results: [],
  },
  toggledTreeIds: [],
  treeUsersChildren: {},
  companyStats: {
    total_devices: 0,
    packages_500_count: 0,
    purchased_devices_count: 0,
  },
  userRefInfo: {
    users_count: 0,
    users_count_first_line: 0,
    levels_count: 0,
    total_devices_in_all_branches: 0,
    max_branch_devices_count: 0,
    other_branches_devices_sum: 0,
  },
};

export const mlmModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
