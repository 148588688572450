import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vSelect from 'vue-select';
import VueClipboard from 'vue-clipboard2';
import ToastPlugin from 'vue-toast-notification';
import DatePicker from 'vue2-datepicker';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VuePhoneNumberInput from 'vue-phone-number-input';
import vueDebounce from 'vue-debounce';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
import '@ckeditor/ckeditor5-build-classic/build/translations/uk';
import 'vue2-datepicker/index.css';
import 'vue-select/dist/vue-select.css';
// import 'vuetify/dist/vuetify.min.css';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/custom.css';
import './assets/css/style.min.css';
import './assets/fonts/Eurostile/stylesheet.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/locale/en';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import moment from 'moment';
import VueI18n from 'vue-i18n';
import {defaultLocale, messages} from '@/i18n';
// font-awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import library from './fa-icons';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';

library.add(faUserLock);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ToastPlugin);
Vue.use(VueClipboard);
Vue.use(CKEditor);
Vue.use(vueDebounce);
Vue.component('datepicker', DatePicker);
Vue.component('v-select', vSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.use(VueI18n);
export const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

Vue.mixin({
  methods: {
    convertDateToStr(date) {
      return moment(String(date)).format('DD.MM.YYYY');
    },
    convertTimeToStr(date) {
      return moment(String(date)).format('HH:mm');
    },
    convertDateTimeToStr(date) {
      return moment(String(date)).format('DD.MM.YYYY HH:mm');
    },
    convertDateTimeToStrCustom(date, format) {
      return moment(String(date)).format(format);
    },
    copyToClipboard(value, successMsg) {
      // const toast = useToast();
      this.$copyText(value.toString()).then((e) => {
        // @ts-ignore
        this.$toast.success(this.$t('Copied successfully'), {
          duration: 2000, position: 'bottom',
        });
      }, (e) => {
        // @ts-ignore
        this.$toast.error(this.$t('Error, unable to copy'), {
          duration: 2000, position: 'bottom',
        });
      });
    },
  }
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
