import { MlmState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IActivePackage, IPackage, IInvoiceResponse, IFinancesResponse, IRecentRegistrationsResponse, IQualification, ICompanyStats, IUserRefInfo } from '@/interfaces/mlm';


export const mutations = {
    setPackagesList(state: MlmState, payload: IPackage[]) {
        state.packagesList = payload;
    },
    setActivePackage(state: MlmState, payload: IActivePackage | null) {
        state.activePackage = payload;
    },
    setQualification(state: MlmState, payload: IQualification | null) {
        state.qualification = payload;
    },
    setInvoiceResponse(state: MlmState, payload: IInvoiceResponse | null) {
        state.invoiceResponse = payload;
    },
    setBinaryTree(state: MlmState, payload: any) {
        state.binaryTree = payload;
    },
    setRecentRegistrations(state: MlmState, payload: IRecentRegistrationsResponse) {
        state.recentRegistrations = payload;
    },
    setTransactionsResponse(state: MlmState, payload: IFinancesResponse) {
        state.transactionsResponse = payload;
    },
    setToggledTreeIds(state: MlmState, payload: number[]) {
        state.toggledTreeIds = payload;
    },
    setTreeUsersChildren(state: MlmState, payload: any) {
        state.treeUsersChildren = payload;
    },
    setCompanyStats(state: MlmState, payload: ICompanyStats) {
        state.companyStats = payload;
    },
    setUserRefInfo(state: MlmState, payload: IUserRefInfo) {
        state.userRefInfo = payload;
    },
};

const {commit} = getStoreAccessors<MlmState | any, State>('');

export const commitSetPackagesList = commit(mutations.setPackagesList);
export const commitSetQualification = commit(mutations.setQualification);
export const commitSetActivePackage = commit(mutations.setActivePackage);
export const commitSetInvoiceResponse = commit(mutations.setInvoiceResponse);
export const commitSetBinaryTree = commit(mutations.setBinaryTree);
export const commitSetRecentRegistrations = commit(mutations.setRecentRegistrations);
export const commitSetTransactionsResponse = commit(mutations.setTransactionsResponse);
export const commitSetToggledTreeIds = commit(mutations.setToggledTreeIds);
export const commitSetTreeUsersChildren = commit(mutations.setTreeUsersChildren);
export const commitSetCompanyStats = commit(mutations.setCompanyStats);
export const commitSetUserRefInfo = commit(mutations.setUserRefInfo);
