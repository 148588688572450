import { MlmState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    packagesList: (state: MlmState) => state.packagesList,
    qualification: (state: MlmState) => state.qualification,
    activePackage: (state: MlmState) => state.activePackage,
    invoiceResponse: (state: MlmState) => state.invoiceResponse,
    binaryTree: (state: MlmState) => state.binaryTree,
    recentRegistrations: (state: MlmState) => state.recentRegistrations,
    transactionsResponse: (state: MlmState) => state.transactionsResponse,
    toggledTreeIds: (state: MlmState) => state.toggledTreeIds,
    treeUsersChildren: (state: MlmState) => state.treeUsersChildren,
    companyStats: (state: MlmState) => state.companyStats,
    userRefInfo: (state: MlmState) => state.userRefInfo,
};

const {read} = getStoreAccessors<MlmState, State>('');

export const readPackagesList = read(getters.packagesList);
export const readQualification = read(getters.qualification);
export const readActivePackage = read(getters.activePackage);
export const readInvoiceResponse = read(getters.invoiceResponse);
export const readBinaryTree = read(getters.binaryTree);
export const readRecentRegistrations = read(getters.recentRegistrations);
export const readTransactionsResponse = read(getters.transactionsResponse);
export const readToggledTreeIds = read(getters.toggledTreeIds);
export const readTreeUsersChildren = read(getters.treeUsersChildren);
export const readCompanyStats = read(getters.companyStats);
export const readUserRefInfo = read(getters.userRefInfo);
