import { api } from "@/api";
import type { FetchRequestInit } from "@/api";
import { IEmirexPaymentRequest, IFinanceFilter } from "@/interfaces/mlm";
import router from "@/router";
import { AxiosError } from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { dispatchCheckApiError, dispatchCheckFormError } from "../main/actions";
import { State } from "../state";
import {
  commitSetActivePackage,
  commitSetBinaryTree,
  commitSetCompanyStats,
  commitSetInvoiceResponse,
  commitSetPackagesList,
  commitSetQualification,
  commitSetRecentRegistrations,
  commitSetTransactionsResponse,
  commitSetUserRefInfo,
} from "./mutations";
import { MlmState } from "./state";

type MainContext = ActionContext<MlmState, State>;

export const actions = {
  async actionAuthFetch(
    context: MainContext,
    payload: { input: string; init?: FetchRequestInit }
  ): Promise<Response> {
    return api.authFetch(
      context.rootState.main.token,
      payload.input,
      payload.init
    );
  },
  async actionGetPackages(context: MainContext) {
    try {
      const response = await api.getPackagesList(context.rootState.main.token);
      if (response.data) {
        commitSetPackagesList(context, response.data);
      }
    } catch (error) {
      commitSetPackagesList(context, []);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPackagesTerms(context: MainContext) {
    try {
      const response = await api.getPackagesTerms(context.rootState.main.token);
      return response.data;
    } catch (error) {
      // await dispatchCheckApiError(context, error);
      return [];
    }
  },
  async actionGetActivePackage(context: MainContext) {
    try {
      const response = await api.getActivePackage(context.rootState.main.token);
      if (response.data) {
        commitSetActivePackage(context, response.data);
      }
    } catch (error) {
      commitSetActivePackage(context, null);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionMakeProfixoneLink(context: MainContext) {
    try {
      const response = await api.makeProfixoneLink(
        context.rootState.main.token
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
  async actionGetCompanyStats(context: MainContext) {
    try {
      const response = await api.getCompanyStats(context.rootState.main.token);
      commitSetCompanyStats(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitSetCompanyStats(context, {
        total_devices: 0,
        packages_500_count: 0,
        purchased_devices_count: 0,
      });
    }
  },
  async actionGetQualification(context: MainContext) {
    try {
      const response = await api.getQualification(context.rootState.main.token);
      if (response.data) {
        commitSetQualification(context, response.data);
      }
    } catch (error) {
      commitSetQualification(context, null);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetOrCreateInvoice(
    context: MainContext,
    data: { package: number; standart: string; token?: string }
  ) {
    try {
      const response = await api.getOrCreateInvoice(
        context.rootState.main.token,
        data
      );
      if (response.data) {
        commitSetInvoiceResponse(context, response.data);
      }
    } catch (error) {
      commitSetInvoiceResponse(context, null);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionProcessConfirmInvoice(
    context: MainContext,
    data: { invoice: number }
  ) {
    try {
      const response = await api.processConfirmInvoice(
        context.rootState.main.token,
        data
      );
      if (response.data) {
        commitSetInvoiceResponse(context, response.data);
      }
      return true;
    } catch (error) {
      // commitSetInvoiceResponse(context, null);
      // await dispatchCheckApiError(context, error);
      return error.response.data;
    }
  },
  // async actionGetOrCreateInvoice(context: MainContext, data: {package: number, standart: string, token?: string}) {
  //     try {
  //         const response = await api.getOrCreateInvoice(context.rootState.main.token, data);
  //         if (response.data) {
  //             commitSetInvoiceResponse(context, response.data);
  //         }
  //     } catch (error) {
  //         commitSetInvoiceResponse(context, null);
  //         await dispatchCheckApiError(context, error);
  //     }
  // },
  // async actionGetOrCreateUpgradeInvoice(context: MainContext, data: {package: number, standart: string, token?: string}) {
  //     try {
  //         const response = await api.getOrCreateUpgradeInvoice(context.rootState.main.token, data);
  //         if (response.data) {
  //             commitSetInvoiceResponse(context, response.data);
  //         }
  //     } catch (error) {
  //         commitSetInvoiceResponse(context, null);
  //         await dispatchCheckApiError(context, error);
  //     }
  // },
  async actionGetOrCreateOrderInvoice(
    context: MainContext,
    data: { order?: number | null; standart: string; token?: string }
  ) {
    try {
      const response = await api.getOrCreateOrderInvoice(
        context.rootState.main.token,
        data
      );
      if (response.data) {
        commitSetInvoiceResponse(context, response.data);
      }
    } catch (error) {
      commitSetInvoiceResponse(context, null);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPaymentStatus(
    context: MainContext,
    data: { address: string; invoice: number }
  ) {
    try {
      const response = await api.getPaymentStatus(
        context.rootState.main.token,
        data
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
  async actionProcessPurchaseByUnit(
    context: MainContext,
    data: { invoice: number }
  ) {
    try {
      const response = await api.processPurchaseByUnit(
        context.rootState.main.token,
        data
      );
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
  async actionGetTransactionsList(
    context: MainContext,
    payload: { filter: IFinanceFilter; rowQuery: string }
  ) {
    try {
      const response = await api.getTransactionsList(
        context.rootState.main.token,
        payload.filter,
        payload.rowQuery
      );
      commitSetTransactionsResponse(context, response.data);
    } catch (error) {
      commitSetTransactionsResponse(context, {
        next: 0,
        previous: 0,
        current: 0,
        count: 0,
        results: [],
      });
      await dispatchCheckApiError(context, error);
    }
  },
  async actionRequestEmirexPayment(
    context: MainContext,
    payload: IEmirexPaymentRequest
  ) {
    try {
      const response = await api.requestEmirexPayment(
        context.rootState.main.token,
        payload
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
  async actionGetEmirexEuroRate(context: MainContext) {
    try {
      const response = await api.getEmirexEuroRate(
        context.rootState.main.token
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
  async actionValidateDexnetAddress(context: MainContext, address: string) {
    try {
      const response = await api.validateDexnetAddress(
        context.rootState.main.token,
        address
      );
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
  async actionCheckDexfreedomTokenInfo(context: MainContext) {
    try {
      const response = await api.checkDexfreedomTokenInfo(
        context.rootState.main.token
      );
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
  async actionSubmitWithdrawRequest(
    context: MainContext,
    data: {
      amount: number;
      standart: string;
      token: string;
      wallet_address: string;
      security_code: string;
    }
  ) {
    try {
      const response = await api.submitWithdrawRequest(
        context.rootState.main.token,
        data
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
  async actionGetUserBinaryObject(
    context: MainContext,
    data: { user: number }
  ) {
    try {
      const response = await api.getUserBinaryObject(
        context.rootState.main.token,
        data
      );
      // if (response.data) {
      //     commitSetActivePackage(context, response.data);
      // }
    } catch (error) {
      // commitSetActivePackage(context, null);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPurchaseStatisticsByPackages(context: MainContext, data: any) {
    try {
      const response = await api.getPurchaseStatisticsByPackages(
        context.rootState.main.token,
        data
      );
      return response.data;
    } catch (error) {
      // commitSetActivePackage(context, null);
      await dispatchCheckApiError(context, error);
      return {
        binary_bonus: 0,
        purchase_statistics: [],
      };
    }
  },
  async actionGetUserBinaryTree(context: MainContext, data: { user: number }) {
    try {
      const response = await api.getUserBinaryTree(
        context.rootState.main.token,
        data
      );
      if (response.data) {
        commitSetBinaryTree(context, response.data);
      }
    } catch (error) {
      commitSetBinaryTree(context, {});
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUserRegistrationsTable(
    context: MainContext,
    payload: { data: { user: number }; rowQuery: string }
  ) {
    try {
      const response = await api.getUserRegistrationsTable(
        context.rootState.main.token,
        payload.data,
        payload.rowQuery
      );
      if (response.data) {
        commitSetRecentRegistrations(context, response.data);
      }
    } catch (error) {
      commitSetRecentRegistrations(context, {
        next: 0,
        previous: 0,
        current: 0,
        count: 0,
        results: [],
      });
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUserStructureList(context: MainContext) {
    try {
      const response = await api.getUserStructureList(
        context.rootState.main.token
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return [];
    }
  },
  async actionSetUserBinaryStructureSide(
    context: MainContext,
    data: { side: string }
  ) {
    try {
      const response = await api.setUserBinaryStructureSide(
        context.rootState.main.token,
        data
      );
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionGetAvailablePromoPackages(context: MainContext) {
    try {
      const response = await api.getAvailablePromoPackages(
        context.rootState.main.token
      );
      return response.data;
    } catch (error) {
      // await dispatchCheckApiError(context, error);
      return [];
    }
  },
  async actionSubmitActivatePromocode(
    context: MainContext,
    payload: { promocode: string }
  ) {
    try {
      const response = await api.submitActivatePromocode(
        context.rootState.main.token,
        payload
      );
      return response.data.success;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      dispatchCheckFormError(context, error);
      return false;
    }
  },
  async actionSubmitCreatePromocode(
    context: MainContext,
    payload: { promocode: number; standart: string; token: string }
  ) {
    try {
      const response = await api.submitCreatePromocode(
        context.rootState.main.token,
        payload
      );
      return response.data.promocode;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      dispatchCheckFormError(context, error);
      return null;
    }
  },
  async actionGetFirstLineUsers(
    context: MainContext,
    payload: { data: any; rowQuery: string }
  ) {
    try {
      const response = await api.getFirstLineUsers(
        context.rootState.main.token,
        payload.data,
        payload.rowQuery
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return [];
    }
  },
  async actionGetFirstLineUsersForUser(
    context: MainContext,
    payload: { user: number; product?: number | null }
  ) {
    try {
      const response = await api.getFirstLineUsersForUser(
        context.rootState.main.token,
        payload
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return [];
    }
  },
  async actionGetReferralStructureInfo(context: MainContext, payload: any) {
    try {
      const response = await api.getReferralStructureInfo(
        context.rootState.main.token,
        payload
      );
      commitSetUserRefInfo(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitSetUserRefInfo(context, {
        users_count: 0,
        users_count_first_line: 0,
        levels_count: 0,
        total_devices_in_all_branches: 0,
        max_branch_devices_count: 0,
        other_branches_devices_sum: 0,
      });
    }
  },
  async actionGetDexSummerPromoStatus(context: MainContext, payload: any) {
    try {
      const response = await api.getDexSummerPromoStatus(
        context.rootState.main.token,
        payload
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return null;
    }
  },
};

const { dispatch } = getStoreAccessors<MlmState | any, State>("");

export const dispAuthFetch = dispatch(actions.actionAuthFetch);
export const dispatchGetPackages = dispatch(actions.actionGetPackages);
export const dispatchGetPackagesTerms = dispatch(
  actions.actionGetPackagesTerms
);
export const dispatchGetActivePackage = dispatch(
  actions.actionGetActivePackage
);
export const dispatchMakeProfixoneLink = dispatch(
  actions.actionMakeProfixoneLink
);
export const dispatchGetCompanyStats = dispatch(actions.actionGetCompanyStats);
export const dispatchGetQualification = dispatch(
  actions.actionGetQualification
);
export const dispatchGetOrCreateInvoice = dispatch(
  actions.actionGetOrCreateInvoice
);
export const dispatchProcessConfirmInvoice = dispatch(
  actions.actionProcessConfirmInvoice
);
// export const dispatchGetOrCreateUpgradeInvoice = dispatch(actions.actionGetOrCreateUpgradeInvoice);
export const dispatchGetOrCreateOrderInvoice = dispatch(
  actions.actionGetOrCreateOrderInvoice
);
export const dispatchGetPaymentStatus = dispatch(
  actions.actionGetPaymentStatus
);
export const dispatchGetUserBinaryObject = dispatch(
  actions.actionGetUserBinaryObject
);
export const dispatchGetUserBinaryTree = dispatch(
  actions.actionGetUserBinaryTree
);
export const dispatchGetPurchaseStatisticsByPackages = dispatch(
  actions.actionGetPurchaseStatisticsByPackages
);
export const dispatchGetUserRegistrationsTable = dispatch(
  actions.actionGetUserRegistrationsTable
);
export const dispatchSetUserBinaryStructureSide = dispatch(
  actions.actionSetUserBinaryStructureSide
);
export const dispatchGetUserStructureList = dispatch(
  actions.actionGetUserStructureList
);
export const dispatchGetTransactionsList = dispatch(
  actions.actionGetTransactionsList
);
export const dispatchValidateDexnetAddress = dispatch(
  actions.actionValidateDexnetAddress
);
export const dispatchSubmitWithdrawRequest = dispatch(
  actions.actionSubmitWithdrawRequest
);
export const dispatchCheckDexfreedomTokenInfo = dispatch(
  actions.actionCheckDexfreedomTokenInfo
);
export const dispatchProcessPurchaseByUnit = dispatch(
  actions.actionProcessPurchaseByUnit
);
export const dispatchGetAvailablePromoPackages = dispatch(
  actions.actionGetAvailablePromoPackages
);
export const dispatchSubmitActivatePromocode = dispatch(
  actions.actionSubmitActivatePromocode
);
export const dispatchSubmitCreatePromocode = dispatch(
  actions.actionSubmitCreatePromocode
);
export const dispatchGetFirstLineUsers = dispatch(
  actions.actionGetFirstLineUsers
);
export const dispatchGetFirstLineUsersForUser = dispatch(
  actions.actionGetFirstLineUsersForUser
);
export const dispatchGetReferralStructureInfo = dispatch(
  actions.actionGetReferralStructureInfo
);
export const dispatchGetDexSummerPromoStatus = dispatch(
  actions.actionGetDexSummerPromoStatus
);
export const dispatchRequestEmirexPayment = dispatch(
  actions.actionRequestEmirexPayment
);
export const dispatchGetEmirexEuroRate = dispatch(
  actions.actionGetEmirexEuroRate
);
