import Vue from "vue";
import Router from "vue-router";

import RouterComponent from "./components/RouterComponent.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("./views/main/Start.vue"),
      children: [
        {
          path: "/",
          component: () =>
            import(/* webpackChunkName: "main" */ "./views/landing/Main.vue"),
          children: [
            {
              path: "/login",
              component: () => import("./views/Login.vue"),
            },
            {
              path: "/sign-up",
              component: () => import("./views/SignUp.vue"),
            },
            {
              path: "/sign-up/:promocode",
              component: () => import("./views/SignUp.vue"),
            },
            {
              path: "/recover-password",
              component: () =>
                import("./views/password-reset/RecoverPassword.vue"),
            },
            {
              path: "/reset-password/:token",
              component: () =>
                import("./views/password-reset/ResetPasswordForm.vue"),
            },
          ],
        },
        {
          path: "/",
          component: () =>
            import(/* webpackChunkName: "main" */ "./views/main/Main.vue"),
          children: [
            {
              path: "dashboard",
              component: () => import("./views/main/Dashboard.vue"),
            },
            {
              path: "team",
              component: () => import("./views/main/Team.vue"),
            },
            {
              path: "transactions",
              component: () => import("./views/main/Transactions.vue"),
            },
            {
              path: "profile",
              component: () =>
                import("./views/main/profile/UserProfileEdit.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/*",
      redirect: "/login",
    },
  ],
});
